import React from 'react';

import Layout from '../components/Layout';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>404 Not found!</h2>
          <p>It appears you've stumble upon a missing page! Spooky!</p>
        </div>
      </header>
  </section>
  </Layout>
);

export default IndexPage;
